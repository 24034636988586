/**
 * Archivo que representa la función reducer de parametros
 * @author Jesús Sarmiento
 */

import * as ActionTypes from '../actions/ActionTypes';

export const parametersInList = (state = {
    isLoading: true,
    errMess: null,
    parameters: []
}, action = {}) => {
     switch(action.type) {
         case ActionTypes.ADD_PARAMETERS_IN:
             return {...state, isLoading: false, errMess: null, parameters: action.payload }
         case ActionTypes.PARAMETERS_IN_LOADING: 
             return {...state, isLoading: true, errMess: null, parameters: [] }
         case ActionTypes.PARAMETERS_IN_FAILED:
             return {...state, isLoading: false, errMess: action.payload, parameters: [] }
         default:
             return state;
     }
}

export const parametersOutList = (state = {
    isLoading: true,
    errMess: null,
    parameters: []
}, action = {}) => {
     switch(action.type) {
         case ActionTypes.ADD_PARAMETERS_OUT:
             return {...state, isLoading: false, errMess: null, parameters: action.payload }
         case ActionTypes.PARAMETERS_OUT_LOADING: 
             return {...state, isLoading: true, errMess: null, parameters: [] }
         case ActionTypes.PARAMETERS_OUT_FAILED:
             return {...state, isLoading: false, errMess: action.payload, parameters: [] }
         default:
             return state;
     }
}

export const serverDate = (state = {
    isLoading: true,
    date: null,
    errMess: null
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_SERVER_DATE:
            return {...state, isLoading: false, errMess: null, date: action.payload }
        case ActionTypes.SERVER_DATE_LOADING:
            return {...state, isLoading: true, errMess: null, date: null }
        case ActionTypes.SERVER_DATE_FAILED:
            return {...state, isLoading: false, errMess: action.payload, date: null }
        default:
            return state;
    }
}

export const parameters = (state = {
    isLoading: true,
    parameters: {},
    errrMess: null
}, action = {}) => {
    switch(action.type) {
       case ActionTypes.ADD_PARAMETERS:
           return {...state, isLoading: false, parameters: action.payload, errrMess: null }
       case ActionTypes.PARAMETERS_LOADING:
           return {...state, isLoading: true, parameters: {}, errrMess: null }
       case ActionTypes.PARAMETERS_FAILED:
           return {...state, isLoading: false, parameters: {}, errrMess: action.payload }
       default:
           return state;
    }
}