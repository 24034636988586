/**
 * Archivo que representa la función reducer de user autenticado
 * @author Jesús Sarmiento
 */

 import * as ActionTypes from '../actions/ActionTypes';

export const user = (state = {
     isLoading: true,
     errMess: null,
     user: null
 }, action = {}) => {
     switch(action.type) {
         case ActionTypes.ADD_AUTHENTICATED_USER:
             return {...state, isLoading: false, errMess: null, user: action.payload }
         case ActionTypes.AUTHENTICATED_USER_LOADING: 
             return {...state, isLoading: true, errMess: null, user: null }
         case ActionTypes.AUTHENTICATED_USER_FAILED:
             return {...state, isLoading: false, errMess: action.payload, user: null }
         default:
             return state;
     }
}

export const adminList = (state = {
    isLoading: true,
    errMess: null,
    admins: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_ADMIN_LIST:
            return {...state, isLoading: false, errMess: null, admins: action.payload }
        case ActionTypes.ADMIN_LIST_LOADING: 
            return {...state, isLoading: true, errMess: null, admins: [] }
        case ActionTypes.ADMIN_LIST_FAILED:
            return {...state, isLoading: false, errMess: action.payload, admins: [] }
        default:
            return state;
    }
}

/**
 * Reducer de lista de solicitudes de afiliación
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const affiliationRequests = (state = {
    isLoading: true,
    errMess: null,
    affiliations: [],
    totalAffiliations: 0
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_AFFILIATION_REQUESTS:
            return {...state, isLoading: false, errMess: null, affiliations: action.payload }
        case ActionTypes.AFFILIATION_REQUESTS_LOADING: 
            return {...state, isLoading: true, errMess: null, affiliations: [] }
        case ActionTypes.AFFILIATION_REQUESTS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, affiliations: [] }
        case ActionTypes.CHANGE_TOTAL_AFFILIATIONS:
            return {...state, totalAffiliations: action.payload }
        default:
            return state;
    }
}


/**
 * Reducer de lista de solicitudes de PAGOS DE CITAS MEDICAS
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
 export const appointmentPaymentRequests = (state = {
    isLoading: true,
    errMess: null,
    appointmentPaymentList: [],
    count: 0
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_APPOINTMENT_PAYMENT_REQUESTS:
            return {...state, isLoading: false, errMess: null, appointmentPaymentList: action.payload }
        case ActionTypes.APPOINTMENT_PAYMENT_REQUESTS_LOADING: 
            return {...state, isLoading: true, errMess: null, appointmentPaymentList: [] }
        case ActionTypes.APPOINTMENT_PAYMENT_REQUESTS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, appointmentPaymentList: [] }
        case ActionTypes.CHANGE_TOTAL_APPOINTMENT_PAYMENT:
            return {...state, count: action.payload }
        default:
            return state;
    }
}
 

 
 