/**
 * Archivo que representa la función reducer de datos de dashboard
 * @author Jesús Sarmiento
 */

 import * as ActionTypes from '../actions/ActionTypes';

export const registeredUsers = (state = {
     isLoading: true,
     errMess: null,
     data: []
}, action = {}) => {
     switch(action.type) {
         case ActionTypes.ADD_REGISTERED_USERS:
             return {...state, isLoading: false, errMess: null, data: action.payload }
         case ActionTypes.REGISTERED_USERS_LOADING: 
             return {...state, isLoading: true, errMess: null, data: [] }
         case ActionTypes.REGISTERED_USERS_FAILED:
             return {...state, isLoading: false, errMess: action.payload, data: [] }
         default:
             return state;
     }
}

export const registeredTenders = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_REGISTERED_TENDERS:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.REGISTERED_TENDERS_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.REGISTERED_TENDERS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const postulations = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_POSTULATIONS:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.POSTULATIONS_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.POSTULATIONS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const postulationsByTender = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_POSTULATIONS_BY_TENDER:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.POSTULATIONS_BY_TENDER_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.POSTULATIONS_BY_TENDER_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const registeredTasks = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_REGISTERED_TASKS:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.REGISTERED_TASKS_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.REGISTERED_TASKS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const accessData = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_ACCESS:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.ACCESS_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.ACCESS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const postulationStates = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_POSTULATION_STATES:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.POSTULATION_STATES_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.POSTULATION_STATES_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const postulationByRange = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_TENDER_POSTULATIONS_BY_RANGE:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.TENDER_POSTULATIONS_BY_RANGE_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.TENDER_POSTULATIONS_BY_RANGE_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}
 
export const companiesData = (state = {
    isLoading: true,
    errMess: null,
    data: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_DASHBOARD_COMPANIES:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.DASHBOARD_COMPANIES_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.DASHBOARD_COMPANIES_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [] }
        default:
            return state;
    }
}

export const dashboardPayroll = (state = {
    isLoading: true,
    errMess: null,
    dashboard: null
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_DASHBOARD_PAYROLL:
            return {...state, isLoading: false, errMess: null, dashboard: action.payload }
        case ActionTypes.DASHBOARD_PAYROLL_LOADING:
            return {...state, isLoading: true, errMess: null, dashboard: null }
        case ActionTypes.DASHBOARD_PAYROLL_FAILED:
            return {...state, isLoading: false, errMess: action.payload, dashboard: null }
        default:
            return state;
    }
}

export const dashboardStore = (state = {
    isLoading: true,
    errMess: null,
    dashboard: null
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_DASHBOARD_STORE:
            return {...state, isLoading: false, errMess: null, dashboard: action.payload }
        case ActionTypes.DASHBOARD_STORE_LOADING:
            return {...state, isLoading: true, errMess: null, dashboard: null }
        case ActionTypes.DASHBOARD_STORE_FAILED:
            return {...state, isLoading: false, errMess: action.payload, dashboard: null }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard general */
export const generalDashData = (state = {
    isLoading: true,
    errMess: null,
    data: {}
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_GENERAL_DASH_DATA:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.GENERAL_DASH_DATA_LOADING: 
            return {...state, isLoading: true, errMess: null, data: {} }
        case ActionTypes.GENERAL_DASH_DATA_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: {} }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard empleo */
export const employmentDashData = (state = {
    isLoading: true,
    errMess: null,
    data: {}
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_EMPLOYMENT_DASH_DATA:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.EMPLOYMENT_DASH_DATA_LOADING: 
            return {...state, isLoading: true, errMess: null, data: {} }
        case ActionTypes.EMPLOYMENT_DASH_DATA_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: {} }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard de tender */
export const tenderDashData = (state = {
    isLoading: true,
    errMess: null,
    data: {}
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_TENDER_DASH_DATA:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.TENDER_DASH_DATA_LOADING: 
            return {...state, isLoading: true, errMess: null, data: {} }
        case ActionTypes.TENDER_DASH_DATA_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: {} }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard de MARKET */
export const marketDashData = (state = {
    isLoading: true,
    errMess: null,
    data: {}
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_MARKET_DASH_DATA:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.MARKET_DASH_DATA_LOADING: 
            return {...state, isLoading: true, errMess: null, data: {} }
        case ActionTypes.MARKET_DASH_DATA_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: {} }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard de academia */
export const academyDashData = (state = {
    isLoading: true,
    errMess: null,
    data: {}
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_ACADEMY_DASH_DATA:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.ACADEMY_DASH_DATA_LOADING: 
            return {...state, isLoading: true, errMess: null, data: {} }
        case ActionTypes.ACADEMY_DASH_DATA_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: {} }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard de delivery */
export const deliveryDashData = (state = {
    isLoading: true,
    errMess: null,
    data: {}
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_DELIVERY_DASH_DATA:
            return {...state, isLoading: false, errMess: null, data: action.payload }
        case ActionTypes.DELIVERY_DASH_DATA_LOADING: 
            return {...state, isLoading: true, errMess: null, data: {} }
        case ActionTypes.DELIVERY_DASH_DATA_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: {} }
        default:
            return state;
    }
}

/** Reducer de datos de dashboard de delivery */
export const networksHistory = (state = {
    isLoading: true,
    errMess: null,
    data: [],
    total: 0
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_NETWORKS_HISTORY:
            return {...state, isLoading: false, errMess: null, data: action.payload?.items, total: action.payload?.total }
        case ActionTypes.NETWORKS_HISTORY_LOADING: 
            return {...state, isLoading: true, errMess: null, data: [] }
        case ActionTypes.NETWORKS_HISTORY_FAILED:
            return {...state, isLoading: false, errMess: action.payload, data: [], total: 0 }
        default:
            return state;
    }
}