import environment from '../environment';
import api from '../utils/interceptor/axios.interceptor';

/**
 * El presente archivo contiene todos los métodos que consumirán los servicios backend de securityApi
 * @author jasarmiento
 */

/**
 * Función encargada de consumir el servicio login del backend de KATUK.
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @version 1.0
 * @since 22/01/2021
 * @param request: datos del usuario a autenticar.
 * 
*/
const login = (request) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, login } = authApi;
    return api().post(
        `${protocol}://${ip}:${port}${baseUrl}${login}`,
        request
    );
}

/**
 * Función encargada de consumir el servicio logout del backend de KATUK.
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @version 1.0
 * @since 22/01/2021
 * @param request
 * 
*/
const logout = (userId) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, logout } = authApi;
    return api().get(
        `${protocol}://${ip}:${port}${baseUrl}${logout}?userId=${userId}&token=`
    );
}

/**
 * Método encargado de registrar al usuario
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @version 1.0
 * @since 09/03/2021
 * @param {object} request 
 */
 const register = (request) => {
   const { ip, protocol, authApi } = environment;
   const { port, baseUrl, register } = authApi;
   return api().post(
       `${protocol}://${ip}:${port}${baseUrl}${register}/${request.email}`,
       request
   );
}

/**
 * Función encargada de consumir el servicio login mediante redes sociales.
 * @param request: datos del usuario a autenticar.
 * 
*/
const loginSocial = (request) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, loginSocial } = authApi;
    return api().post(
        `${protocol}://${ip}:${port}${baseUrl}${loginSocial}`,
        request
    );
}

/**
 * Función encargada de Consumir el servicio de cambio de contraseña
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @since 26/11/2020
 * @param {*} request 
 */
const changePassword = (request, userModification) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, changePassword } = authApi;
    return api(userModification).post(
        `${protocol}://${ip}:${port}${baseUrl}${changePassword}`,
        request
    );
} 

/**
 * Función encargada de Consumir el servicio de recuperar contraseña
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @since 26/11/2020
 * @param {String} email 
 */
const recoveryPassword = (email) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, recoveryPassword } = authApi;
    return api(email).get(
        `${protocol}://${ip}:${port}${baseUrl}${recoveryPassword}?email=${email}`
    );
} 

/**
 * Obtener lista de solicitudes de afiliación
 * @param {*} userEmail 
 */
 const getAffiliationRequest = (searchParams, page, pageSize, userEmail) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, getAffiliationRequest } = authApi;
    let searchParamsLabel = '';
    for (var [key, value] of Object.entries(searchParams)) {
        searchParamsLabel = `${searchParamsLabel}${searchParamsLabel.length === 0 ? '' : '&'}${key}=${value}`;
    }
    return api(userEmail).get(
        `${protocol}://${ip}:${port}${baseUrl}${getAffiliationRequest}?pageNo=${page}&pageSize=${pageSize}&${searchParamsLabel}`
    );
}

/**
 * Actualización de estado de solicitud de afiliación
 * @param {*} request 
 * @param {*} userEmail 
 */
const editAffiliationStatus = (request, userEmail) => {
    const { ip, protocol, authApi } = environment;
    const { port, baseUrl, editAffiliationStatus } = authApi;
    return api(userEmail).put(
        `${protocol}://${ip}:${port}${baseUrl}${editAffiliationStatus}`,
        request
    );
}
/**
 * Obtener lista de solicitudes de afiliación
 * @param {*} userEmail 
 */
 const getAppointmentPaymentRequest = (searchParams, page, pageSize, userEmail) => {
    const { ip, protocol, doctorApi } = environment;
    const { port, baseUrl, getAppointmentPaymentRequest } = doctorApi;
    let searchParamsLabel = '';
    for (var [key, value] of Object.entries(searchParams)) {
        searchParamsLabel = `${searchParamsLabel}${searchParamsLabel.length === 0 ? '' : '&'}${key}=${value}`;
    }
    return api(userEmail).get(
        `${protocol}://${ip}:${port}${baseUrl}${getAppointmentPaymentRequest}?pageNo=${page}&pageSize=${pageSize}&${searchParamsLabel}`
    );
}
/**
 * Actualización de estado de solicitud de pagos cita médica
 * @param {*} request 
 * @param {*} userEmail 
 */
 const editPaymentStatus = (request, userEmail) => {
    const { ip, protocol, doctorApi } = environment;
    const { port, baseUrl, updateAppointmentPaymentRequest } = doctorApi;
    return api(userEmail).put(
        `${protocol}://${ip}:${port}${baseUrl}${updateAppointmentPaymentRequest}`,
        request
    );
}
export {
    login,
    register,
    changePassword,
    recoveryPassword,
    loginSocial,
    logout,
    getAffiliationRequest,
    editAffiliationStatus,
    getAppointmentPaymentRequest,
    editPaymentStatus
}