/**
 * Archivo que representa la función reducer de user autenticado
 * @author Jesús Sarmiento
 */

import { ordersPageSize } from '../../environment/general';
import * as ActionTypes from '../actions/ActionTypes';

/**
 * Función reducer de órdenes
 * @param {*} state 
 * @param {*} action 
 */
export const ordersList = (state = {
    isLoading: true,
    errMess: null,
    orders: [],
    total: 0
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.LOAD_ORDERS_LIST:
            return {...state, isLoading: false, errMess: null, orders: action.payload?.items, total: action.payload?.total }
        case ActionTypes.ORDERS_LIST_LOADING:
            return {...state, isLoading: true, errMess: null, orders: [] }
        case ActionTypes.ORDERS_LIST_FAILED:
            return {...state, isLoading: false, errMess: action.payload, orders: [], total: 0 }
        default:
            return state;
    }
}

/**
 * Función reducer de detalle de orden
 * @param {*} state 
 * @param {*} action 
 */
export const orderDetail = (state = {
    isLoading: true,
    errMess: null,
    order: null
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_ORDER_DETAIL:
            return {...state, isLoading: false, errMess: null, order: action.payload }
        case ActionTypes.ORDER_DETAIL_LOADING:
            return {...state, isLoading: true, errMess: null, order: null }
        case ActionTypes.ORDER_DETAIL_FAILED:
            return {...state, isLoading: false, errMess: action.payload, order: null }
        default:
            return state;
    }
}

/**
 * Función reducer de detalle de orden
 * @param {*} state 
 * @param {*} action 
 */
export const orderDelivery = (state = {
    isLoading: true,
    errMess: null,
    delivery: null
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_ORDER_DELIVERY:
            return {...state, isLoading: false, errMess: null, delivery: action.payload }
        case ActionTypes.ORDER_DELIVERY_LOADING:
            return {...state, isLoading: true, errMess: null, delivery: null }
        case ActionTypes.ORDER_DELIVERY_FAILED:
            return {...state, isLoading: false, errMess: action.payload, delivery: null }
        default:
            return state;
    }
}

/**
 * Función reducer de filtros de órdenes
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @version 1.0
 * @since 29/01/2024
 */
export const ordersFilter = (state = {
    filter: {}
}, action = {}) => {
    if (action.type === ActionTypes.CHANGE_ORDERS_FILTER) {
        return {...state, filter: action.payload }
    }
    return state;
}

export const ordersLazyParams = (state = {
    params: {
        first: 0,
        rows: ordersPageSize,
        page: 0,
    }
}, action = {}) => {
    if (action.type === ActionTypes.CHANGE_ORDERS_LAZY_PARAMS) {
        return {...state, params: action.payload }
    }
    return state;
}

/**
 * Función reducer de órdenes
 * @param {*} state 
 * @param {*} action 
 */
export const penaltiesList = (state = {
    isLoading: true,
    errMess: null,
    penalties: []
}, action = {}) => {
    switch(action.type) {
        case ActionTypes.ADD_PENALTIES:
            return {...state, isLoading: false, errMess: null, penalties: action.payload }
        case ActionTypes.PENALTIES_LOADING:
            return {...state, isLoading: true, errMess: null, penalties: [] }
        case ActionTypes.PENALTIES_FAILED:
            return {...state, isLoading: false, errMess: action.payload, penalties: [] }
        default:
            return state;
    }
}