/**
 * Archivo que representa la función reducer catalogos
 * @author Jesús Sarmiento
 */

 import * as ActionTypes from '../actions/ActionTypes';

 export const fieldsList = (state = {
    isLoading: true,
    errMess: null,
    fields: []
}, action = {}) => {
     switch(action.type) {
         case ActionTypes.ADD_FIELDS_LIST:
             return {...state, isLoading: false, errMess: null, fields: action.payload }
         case ActionTypes.FIELDS_LIST_LOADING: 
             return {...state, isLoading: true, errMess: null, fields: [] }
         case ActionTypes.FIELDS_LIST_FAILED:
             return {...state, isLoading: false, errMess: action.payload, fields: [] }
         default:
             return state;
     }
}


/** FORMULARIOS DINAMICOS */
export const formsList = (state = {
    isLoading: true,
    errMess: null,
    fields: []
}, action = {}) => {
     switch(action.type) {
         case ActionTypes.ADD_FORMS_LIST:
             return {...state, isLoading: false, errMess: null, fields: action.payload }
         case ActionTypes.FORMS_LIST_LOADING: 
             return {...state, isLoading: true, errMess: null, fields: [] }
         case ActionTypes.FORMS_LIST_FAILED:
             return {...state, isLoading: false, errMess: action.payload, fields: [] }
         default:
             return state;
     }
}

/** FORMULARIOS DETALLE */
export const formDetail = (state = {
    isLoading: true,
    errMess: null,
    form: null
    }, action = {}) => {
   switch(action.type) {
       case ActionTypes.ADD_FORMS_DETAIL:
           return {...state, isLoading: false, errMess: null, form: action.payload }
       case ActionTypes.FORMS_DETAIL_LOADING:
           return {...state, isLoading: true, errMess: null, form: null }
       case ActionTypes.ADD_DETAIL_FAILED:
           return {...state, isLoading: false, errMess: action.payload, form: null }
       default:
           return state;
   }
}

export const penaltyReasons = (state = {
    isLoading: true,
    errMess: null,
    reasons: []
    }, action = {}) => {
   switch(action.type) {
       case ActionTypes.ADD_PENALTY_REASONS:
           return {...state, isLoading: false, errMess: null, reasons: action.payload }
       case ActionTypes.PENALTY_REASONS_LOADING:
           return {...state, isLoading: true, errMess: null, reasons: [] }
       case ActionTypes.PENALTY_REASONS_FAILED:
           return {...state, isLoading: false, errMess: action.payload, reasons: [] }
       default:
           return state;
   }
}