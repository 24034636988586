import deploy from './deploy';

const getEnvironment = () => {
    return deploy;
}

const environment = getEnvironment();

export default environment

