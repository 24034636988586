import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import store from './redux/store';
import environment from './environment';

const path = environment.webBaseUrl;

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename={`${path}`}>
        <AppWrapper />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
