import axios from 'axios';
import environment from '../../environment';

const path = environment.webBaseUrl;

const api = (userModification = '') => {
	const token = localStorage.getItem('ktoken') ? localStorage.getItem('ktoken'): '';
	const api = axios.create({
		headers: {
            app: 'DASHBOARD',
            channel: 'WEB',
            deviceId: '',
            so: '',
            user: userModification,
			//Authorization: 'Bearer ' + token,
			ktoken: token
		}
	});
	api.interceptors.response.use(function (response) {
		// Do something with response data
		const { data } = response;
		if(data.code === 123 || data.code === 124) {
			localStorage.clear();
			sessionStorage.clear();
			return window.location.href = `${path}/`;
		}
		return response;
	}, function (error) {
		// Do something with response error
		if(error.response?.status === 403 || error.response?.status === 401) {
			localStorage.clear();
			sessionStorage.clear();
			return window.location.href = `${path}/`;
		}
		// Trow errr again (may be need for some other catch)
		return Promise.reject(error);
	});
	return api;
}

export default api;