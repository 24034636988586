import jwtDecode from 'jwt-decode';
//import moment from 'moment';

/**
 * Función encargada de verificar que exista el token del usuario logeado, si no existe el token,
 * se considera que el usuario no esta autenticado.
 * @author Jesús Alberto Sarmiento Carrillo
 * @version 1.0
 * @since 10/11/2020
 */
const isAuthenticated = () => {
	try {
		/*Verificar si el token existe*/
		if (getToken()) {
			/* const token = getToken();
			const tokenDecoded = jwtDecode(token);
			if (tokenDecoded.exp > moment().unix()) {
				return true;
			} else {
                console.log('Su sesión ha sido expirada.')
				localStorage.clear();
			} */
			return true;
		}
	} catch(e) {
		return false;
	}
	return false;
}

/**
 * Obtiene el token de autenticación del local storage
 */
const getToken = () => {
    return localStorage.getItem('ktoken');
}

/**
 * Obtiene email del usuario
 */
const getEmail = () => {
	return localStorage.getItem('email');
}

/**
 * Obtiene el token de autenticación decodificado
 * @param token: token que necesita ser decodificado
 */
const decode = (token) => {
    return jwtDecode(token);
}

export {
    isAuthenticated,
    getToken,
	decode,
	getEmail
};