import React from 'react';
import './style.scss';
/**
 * Componente funcional que representa el spinner de carga al inicio de la web
 * @param {*} props 
 */
function Preloader(props) {
    return(
        <div id="preloader">
            <div id="ctn-preloader" className="ctn-preloader">
                <div className="animation-preloader">
                    <div className="spinner"></div>
                    <div className="txt-loading">
                        <span data-text-preloader="K" className="letters-loading">
                            K
                        </span>
                        <span data-text-preloader="T" className="letters-loading">
                            T
                        </span>
                        <span data-text-preloader="A" className="letters-loading">
                            A
                        </span>
                        <span data-text-preloader="S" className="letters-loading">
                            S
                        </span>
                        <span data-text-preloader="K" className="letters-loading">
                            K
                        </span>
                    </div>
                    <p>Cargando...</p>
                </div>
            </div>
        </div>
    );
}

export default Preloader;