const dateTypes = [
    { code: 'THIS_WEEK', name: 'Últimos 7 días'},
    { code: 'THIS_MONTH', name: 'Últimos 30 días'},
    { code: 'HALF_YEAR', name: 'Últimos 6 meses'},
    { code: 'MONTH_YEAR', name: 'Último año'},
    { code: 'ALL', name: 'Todo'},
    { code: 'CUSTOM', name: 'Personalizado' }
];

const tenderTypes = [
    { code: 'EMPLOYMENTS', name: 'Empleo'},
    { code: 'PRODUCTS', name: 'Producto'},
    { code: 'SERVICES', name: 'Servicio'}
];

const affiliationStatus = [
    { code: 'PENDIENTE', name: 'Pendiente' },
    { code: 'APROBADO', name: 'Aprobado' },
    { code: 'RECHAZADO', name: 'Rechazado' },
    { code: 'NOVEDAD', name: 'Novedad' }
];

const fieldTypes = [
    { code: 'text', name: 'Texto' },
    { code: 'number', name: 'Número' },
    { code: 'file', name: 'Archivo' }
];

const dataTypesItems = [
    { code: 'SEMANAL', name: 'Semanal'},
    { code: 'NORMAL', name: 'Mensual'},
    { code: 'CUMULATIVE', name: 'Cumulativo'}
];

const affiliationFormTypes = [
    { code: 'PAYROLLBUSINESS_AFFILIATION_FORM', name: 'Formulario de Descuento a Nómina' },
    { code: 'DOCTOR_AFFILIATION_FORM', name: 'Formulario de Doctores Independientes' },
    { code: 'STORE_AFFILIATION_FORM', name: 'Formulario de Tienda' },
    { code: 'DOCTORBUSINESS_AFFILIATION_FORM', name: 'Formulario de Empresa para Katuk Doctor' },
    { code: 'DOCTOR_COLLABORATOR_FORM', name: 'Formulario de Doctores Dependientes' },
    { code: 'FOODS_AFFILIATION_FORM', name: 'Formulario de Afiliación de Restaurantes' },
    { code: 'MARKETPLACE_AFFILIATION_FORM', name: 'Formulario de Marketplace' },
];

const typePayment = [
    { code: 'TOD', name: 'Transferencia o Depósito'}
];

const requestAppointmentStatus = [
    { code: 'PENDIENTE', name: 'Pendiente' },
    { code: 'APROBADO', name: 'Aprobado' },
    { code: 'RECHAZADO', name: 'Rechazado' }
];
const imagesExtensions = ['png', 'jpg', 'jpeg'];
const originDate = '2020-10-01';
const pageSize = 15;
const ordersPageSize = 30;

const providerTypes = [
    { code: 'ALL', name: 'Todo' },
    { code: 'DOCTOR', name: 'Doctor' },
    { code: 'STORE', name: 'Tienda' },
    { code: 'FOOD', name: 'Comida' }
];

const networksItems = [
    { code: 'tiktok', name: 'TikTok' },
    { code: 'facebook', name: 'Facebook' },
    { code: 'instagram', name: 'Instagram' },
    { code: 'linkedin', name: 'Linkedin' },
    { code: 'twitter', name: 'X' }
];

const orderModule = [
    { code: 'STORE', name: 'Supermercado', order: 1 },
    { code: 'MARKETPLACE', name: 'Marketplace', order: 2 },
    { code: 'FOODS', name: 'Restaurante', order: 3 },
    { code: 'ACADEMY', name: 'Academia', order: 4 }
];

const orderStates = [
    { code: 'PENDIENTE', name: 'Pendiente', order: 1 },
    { code: 'EN_CURSO', name: 'En Preparación', order: 2 },
    { code: 'EN_ENTREGA', name: 'En Ruta', order: 3 },
    { code: 'COMPLETADO', name: 'Finalizado', order: 4 },
    { code: 'PENDIENTE_CANCELACION', name: 'Por Cancelar', order: 5 },
    { code: 'CANCELADO', name: 'Cancelado', order: 6 }
];

const orderTypes = [
    { code: 'STORE', name: 'Supermercado', order: 1 },
    { code: 'FOODS', name: 'Restaurante', order: 2 },
    { code: 'DOCTOR', name: 'Medicina', order: 3 },
    { code: 'ACADEMY', name: 'Academia', order: 4 },
    { code: 'MARKETPLACE', name: 'Marketplace', order: 5 }
]; 

const paymentMethods = [
    { code: 'BTP', name: 'Tarjeta de Débito o Crédito' },
    { code: 'DSN', name: 'Descuento a Nómina' }
];

const penaltyTypes = [
    { code: 'CLIENTE', name: 'Cliente' },
    { code: 'PROVEEDOR', name: 'Proveedor' }
];

export {
    dateTypes,
    originDate,
    pageSize,
    tenderTypes,
    affiliationStatus,
    fieldTypes,
    dataTypesItems,
    affiliationFormTypes,
    imagesExtensions,
    typePayment,
    requestAppointmentStatus,
    providerTypes,
    networksItems,
    ordersPageSize,
    orderModule,
    orderStates,
    orderTypes,
    paymentMethods,
    penaltyTypes
}