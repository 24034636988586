import React, { useEffect, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLoggedInfo } from './redux/actions/users';
import Logout from './pages/Login/Logout';
import Preloader from './components/Preloader';
const Login = lazy(() => import('./pages/Login'));
const Main = lazy(() => import('./pages/Main'));


function AppWrapper(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(state => state.user);
	
    useEffect(() => {
        dispatch(getUserLoggedInfo());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);
	
    const LoginPage = () => {
        if (user.user) {
            return(
                <Redirect to="/" />
            );
        } else {
            return(
                <Login 
                    {...props} />
            );
        }
    }

    if (user.isLoading) {
        return(
            <Preloader />
        );
    }

    return(
        <Suspense fallback={<Preloader />}>
            <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/logout" component={Logout}/>
                <Main pathname={location.pathname} />
            </Switch>
        </Suspense>
    );
}

export default withRouter(AppWrapper);
