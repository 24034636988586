/**
 * Archivo que representa al store creado en redux
 * @author Jesús Sarmiento
 */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { user, adminList, affiliationRequests,appointmentPaymentRequests } from './reducers/users';
import { registeredUsers, registeredTenders, postulations, postulationsByTender,
    registeredTasks, accessData, postulationStates, postulationByRange, companiesData, dashboardPayroll, dashboardStore,
    generalDashData, employmentDashData, tenderDashData, marketDashData, academyDashData, deliveryDashData,
    networksHistory } from './reducers/dashboard';
import { parametersInList, parametersOutList, serverDate, parameters } from './reducers/parameters';
import { fieldsList, formsList, formDetail, penaltyReasons } from './reducers/catalog';
import { ordersList, orderDetail, ordersFilter, ordersLazyParams, penaltiesList  } from './reducers/orders';
 
 /**
  * Combinación de reducers
  */
 const reducer = combineReducers({
     user,
     registeredUsers,
     registeredTenders,
     postulations, 
     postulationsByTender,
     registeredTasks,
     accessData,
     postulationStates,
     postulationByRange,
     parametersInList,
     parametersOutList,
     adminList,
     affiliationRequests,
     fieldsList,
     formsList,
     formDetail,
     appointmentPaymentRequests,
     companiesData,
     serverDate,
     dashboardPayroll,
     dashboardStore,
     generalDashData,
     employmentDashData,
     tenderDashData,
     marketDashData,
     academyDashData,
     deliveryDashData,
     networksHistory,
     parameters,
     ordersList,
     orderDetail,
     ordersFilter,
     ordersLazyParams,
     penaltyReasons,
     penaltiesList
 });
 
 /**
  * Creación de store con middleware thunk y logger
  */
 const store = createStore(reducer, applyMiddleware(thunk));
 
 export default store;