/**
 * Archivo que contiene las acciones relacionadas al usuario
 */
import * as ActionTypes from './ActionTypes';
import { getAdmins, getUserLogged } from '../../services/user';
import { getEmail, getToken } from '../../utils/auth/auth';
import environment from '../../environment';
import { getAffiliationRequest, getAppointmentPaymentRequest } from '../../services/auth';
const path = environment.webBaseUrl;
 
 /**
  * Obtener información del usuario logeado
  * @author Jesus Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
  * @version 1.0
  * @since 23/11/2020
  * 
  */
 export const getUserLoggedInfo = () => (dispatch) => {
     dispatch(authUserLoading());
     if (getToken()) {
         const email = getEmail();
         if (email) {
             getUserLogged(email, 'NO')
             .then(response => {
                 const { data } = response;
                 if (data.code === 104) {
                    const user = data.data;
                    if (user.accessDashboard) {
                        dispatch(addAuthUser(user));
                    } else {
                        localStorage.clear();
                        sessionStorage.clear();
                        return window.location.href = `${path}/`;
                    }
                 } else {
                    dispatch(authUserFailed('Usuario no autenticado.'));
                 }
             })
             .catch(error => {
                 console.log(error);
                 dispatch(authUserFailed('Usuario no autenticado.'));
             });
         } else {
             dispatch(authUserFailed('Usuario no autenticado.'));
         }
     } else {
         dispatch(authUserFailed('Usuario no autenticado.'));
     }
 }
 
export const authUserLoading = () => ({
    type: ActionTypes.AUTHENTICATED_USER_LOADING
});

export const addAuthUser = (user) => ({
    type: ActionTypes.ADD_AUTHENTICATED_USER,
    payload: user
});

export const authUserFailed = (errmess) => ({
    type: ActionTypes.AUTHENTICATED_USER_FAILED,
    payload: errmess
});

/** ACCIONES DE LISTA DE ADMINISTRADORES */
export const fetchAdminList = (userEmail) => (dispatch) => {
    dispatch(adminListLoading());
    getAdmins(userEmail)
    .then(response => {
        const { data } = response;
        if (data.code === 104) {
            let items = data.personList;
            dispatch(addAdminList(items));
        } else if (data.code === 105) {
            dispatch(addAdminList([]));
        } else {
            var error = {
                code: data.code,
                message: data.description || data.message
            }
            throw error;
        }
    })
    .catch(error => {
        dispatch(adminListFailed(error));
    });
}

export const adminListLoading = () => ({
    type: ActionTypes.ADMIN_LIST_LOADING
});

export const addAdminList = (item) => ({
    type: ActionTypes.ADD_ADMIN_LIST,
    payload: item
});

export const adminListFailed = (errmess) => ({
    type: ActionTypes.ADMIN_LIST_FAILED,
    payload: errmess
});

/** ACCIONES DE SOLICITUDES DE AFILIACIÓN */
export const fetchAffiliationRequests = (searchParams, page, pageSize, userEmail) => (dispatch) => {
    dispatch(affiliationRequestsLoading());
    getAffiliationRequest(searchParams, page, pageSize, userEmail)
    .then(response => {
        const { data } = response;
        if (data.code === 104) {
            let items = data.affiliationList;
            dispatch(addAffiliationRequests(items));
            dispatch(changeTotalAffiliations(data.count || 0));
        } else if (data.code === 105) {
            dispatch(addAffiliationRequests([]));
        } else {
            var error = {
                code: data.code,
                message: data.description || data.message
            }
            throw error;
        }
    })
    .catch(error => {
        dispatch(affiliationRequestsFailed(error));
    });
}

export const refreshAffiliationRequests = (searchParams, page, pageSize, userEmail) => (dispatch) => {
    getAffiliationRequest(searchParams, page, pageSize, userEmail)
    .then(response => {
        const { data } = response;
        if (data.code === 104) {
            let items = data.affiliationList;
            dispatch(addAffiliationRequests(items));
            dispatch(changeTotalAffiliations(data.count || 0));
        } else if (data.code === 105) {
            dispatch(addAffiliationRequests([]));
        } else {
            var error = {
                code: data.code,
                message: data.description || data.message
            }
            throw error;
        }
    })
    .catch(error => {
        dispatch(affiliationRequestsFailed(error));
    });
}

export const affiliationRequestsLoading = () => ({
    type: ActionTypes.AFFILIATION_REQUESTS_LOADING
});

export const addAffiliationRequests = (items) => ({
    type: ActionTypes.ADD_AFFILIATION_REQUESTS,
    payload: items
});

export const affiliationRequestsFailed = (errmess) => ({
    type: ActionTypes.AFFILIATION_REQUESTS_FAILED,
    payload: errmess
});

export const changeTotalAffiliations = (total) => ({
    type: ActionTypes.CHANGE_TOTAL_AFFILIATIONS,
    payload: total
});


/** ACCIONES DE SOLICITUDES DE PAGO DE DOCTORES */
export const fetchPaymentsAppointmentRequests = (searchParams, page, pageSize, userEmail) => (dispatch) => {
    dispatch(paymentsAppointmentLoading());
    getAppointmentPaymentRequest(searchParams, page, pageSize, userEmail)
    .then(response => {
        const { data } = response;
        if (data.code === 104) {
            let items = data.appointmentPaymentList;
            dispatch(addAPaymentsAppointmentRequests(items));
            dispatch(changeTotalPaymentsAppointment(data.count || 0));
        } else if (data.code === 105) {
            dispatch(addAPaymentsAppointmentRequests([]));
        } else {
            var error = {
                code: data.code,
                message: data.description || data.message
            }
            throw error;
        }
    })
    .catch(error => {
        dispatch(paymentsAppointmentRequestsFailed(error));
    });
}


export const paymentsAppointmentLoading = () => ({
    type: ActionTypes.APPOINTMENT_PAYMENT_REQUESTS_LOADING
});

export const addAPaymentsAppointmentRequests = (items) => ({
    type: ActionTypes.ADD_APPOINTMENT_PAYMENT_REQUESTS,
    payload: items
});

export const paymentsAppointmentRequestsFailed = (errmess) => ({
    type: ActionTypes.APPOINTMENT_PAYMENT_REQUESTS_FAILED,
    payload: errmess
});

export const changeTotalPaymentsAppointment = (total) => ({
    type: ActionTypes.CHANGE_TOTAL_APPOINTMENT_PAYMENT,
    payload: total
});
