import environment from '../environment';
import api from '../utils/interceptor/axios.interceptor';

/**
 * El presente archivo contiene todos los métodos que consumirán los servicios backend de userApi
 * @author Jesús Sarmiento
 */

/**
  * Obtener información del usuario logeado
  * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
  * @version 1.0
  * @since 23/11/2020
  * 
  * @param {String} email 
  * @param {String} isCompany 
  */
 const getUserLogged = (email, isCompany) => {
    const { ip, protocol, userApi } = environment;
    const { port, baseUrl, getUserById } = userApi;
    return api(email).get(
        `${protocol}://${ip}:${port}${baseUrl}${getUserById}/${isCompany}/ACTIVO?email=${email}`
    );
}

/**
 * Buscar usuarios, ya sea usuarios o equipos
 * @param {*} search 
 * @param {*} userId 
 * @param {*} userEmail 
 * @returns 
 */
 const findUsers = (search, userId, userEmail) => {
    const { ip, protocol, userApi } = environment;
    const { port, baseUrl, findStakeholders } = userApi;
    return api(userEmail).get(
        `${protocol}://${ip}:${port}${baseUrl}${findStakeholders}?userId=${userId}&name=${search}`,
    );
}

/**
 * Agregar administrador de dashboard
 * @param {*} request 
 * @param {*} userEmail 
 */
const addAdminDash = (request, userEmail) => {
    const { ip, protocol, userApi } = environment;
    const { port, baseUrl, addAdminDash } = userApi;
    return api(userEmail).post(
        `${protocol}://${ip}:${port}${baseUrl}${addAdminDash}`,
        request
    );
}

/**
 * Obtener lista de administradores
 * @param {*} userEmail 
 */
const getAdmins = (userEmail) => {
    const { ip, protocol, userApi } = environment;
    const { port, baseUrl, getAdmins } = userApi;
    return api(userEmail).get(
        `${protocol}://${ip}:${port}${baseUrl}${getAdmins}`
    );
}

/**
 * Eliminar usuario de la lista de administradores
 * @param {*} userId 
 * @param {*} userEmail 
 */
const deleteAdmin = (userId, userEmail) => {
    const { ip, protocol, userApi } = environment;
    const { port, baseUrl, deleteAdmin } = userApi;
    return api(userEmail).delete(
        `${protocol}://${ip}:${port}${baseUrl}${deleteAdmin}?userId=${userId}`
    );
}

export {
    getUserLogged,
    findUsers,
    addAdminDash,
    getAdmins,
    deleteAdmin
}