import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import environment from '../../environment';
import { logout } from '../../services/auth';
import './style.scss';

const path = environment.webBaseUrl;

/**
 * Componente funcional que realizará el logout en la página
 * @param {*} props 
 */
function Logout(props) {
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        logout(user.userId)
        .then(response => {
            const { data } = response;
            if (data.code === 200) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = `${path}/login`;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    

    return(
        <div id="preloader">
            <div id="ctn-preloader-logout" className="ctn-preloader">
                <div className="animation-preloader-logout">
                    <div className="spinner"></div>
                    <div className="txt-loading">
                        <span data-text-preloader="K" className="letters-loading">
                            K
                        </span>
                        <span data-text-preloader="T" className="letters-loading">
                            T
                        </span>
                        <span data-text-preloader="A" className="letters-loading">
                            A
                        </span>
                        <span data-text-preloader="S" className="letters-loading">
                            S
                        </span>
                        <span data-text-preloader="K" className="letters-loading">
                            K
                        </span>
                    </div>
                    <p>Cerrando Sesión...</p>
                </div>
            </div>
        </div>
    );
}
export default Logout;