const environment = {
    webBaseUrl: '',
    KT_BASE_PATH_DOCUMENT_URL: 'https://backoffice.katuk.pro/ktask/document/',
    KT_BASE_PATH_DOCUMENT_URL_REPLACE: 'https://backoffice.katuk.pro/katuk/document/',
    protocol: 'https',
    ip: 'backoffice.katuk.pro',
    webSocket: {
        protocol: 'https',
        baseUrl: '/api/ms-core-notification/core/v1.0',
        domain: 'backoffice.katuk.pro',
        port: '443',
        connect: '/backoffice/websocket'
    },
    notificationApi: {
        protocol: 'https',
        domain: 'backoffice.katuk.pro',
        baseUrl: '/api/ms-core-notification/core',
        port: '443',
        connect: '/v1.0/katuk/websocket',
        getNotifications: '/v1.0/notification/find/group',
        notify: '/v1.0/notification',
        history: '/v1.0/history/task',
        updateNotificationState: '/v1.0/notification/update',
        clearNotification: '/v1.0/notification/cleanNotification',
        getNotificationChildren: '/v1.0/notification/find/task'
    }, 
    authApi: {
        baseUrl: '/api/ms-comp-auth/core/v1.0',
        port: '443',
        login: '/login',
        loginSocial: '/login/social/network',
        recoveryPassword: '/password/forget',
        changePassword: '/password/change',
        register: '/create',
        requestToken: '/oauth/request_token',
        accessToken: '/oauth/access_token',
        logout: '/klogout',
        getAffiliationRequest: '/affiliation/find',
        editAffiliationStatus: '/affiliation/update',
        getAppointmentPaymentRequest: '/appointment/findAppointmentPayments'
    },
    doctorApi: {
        baseUrl: '/api/ms-comp-doctor/doctor/v1.0',
        port: '443',
        getAppointmentPaymentRequest: '/appointment/findAppointmentPayments',
        updateAppointmentPaymentRequest: '/appointment/updateAppointmentPayments'
    },
    userApi: {
        baseUrl: '/api/ms-comp-user/core/v1.0',
        port: '443',
        getAcademyList: '/academy/find',
        addAcademy: '/academy/create',
        editAcademy: '/academy/update',
        deleteAcademy: '/academy/delete',
        getExperienceList: '/work/find',
        addExperience: '/work/create',
        editExperience: '/work/update',
        deleteExperience: '/work/delete',
        getUserById: '/find',
        editUser: '/update',
        getPersonInfo: '/find/person',
        getUserConfigurations: '/configuration/find',
        editConfiguration: '/configuration/update',
        editProfileImage: '/upload/photo',
        teamCreate: '/team/create',
        teamFind: '/team/find/team',
        teamFindMembers: '/team/find/person',
        getStakeholderMembersTeam: '/find/membersteam',
        teamAssignAdmin: '/team/assign/admin',
        teamUnassignAdmin: '/team/unassign/admin',
        teamAssignMember: '/team/assign',
        teamUnassignMember: '/team/unassign',
        teamUpdate: '/team/update',
        teamDelete: '/team/delete',
        findStakeholders: '/findPersonStakeholders',
        findPersonAllowPerformance: '/performanceview/find',
        personAllowPerformanceAdd: '/performanceview/add',
        personAllowPerformanceRemove: '/performanceview/remove',
        blockUser: '/block',
        findUsersBlock: '/block/find',
        unBlockUser: '/unblock',
        validateBlockUser: '/validateBlockUser',
        addAdminDash: '/access/create',
        getAdmins: '/access/find',
        deleteAdmin: '/access/delete'
    },
    coreApi: {
        baseUrl: '/api/ms-core-catalog/core/v1.0',
        port: '443',
        getCountries: '/nationality/find',
        getTaxonomy: '/taxonomy/find',
        getFiles: '/document/find',
        getUniversities: '/university/find',
        getCompanies: '/company/find',
        getDocumentCatalog: '/document/find',
        getParameter: '/parameter/find',
        getServerDate: '/parameter/getserverdate',
        updateParameter: '/katsParameters/update',
        getKatsParameters: '/katsParameters/find',
        getAffiliationFields: '/affiliationFields/find',//formulario dinamico campos
        createAffiliationField: '/affiliationFields/create',
        updateAffiliation: '/affiliationFields/update',
        deleteAffiliationField: '/affiliationFields/delete',
        getForms:'/affiliationFields/findForms',
        createForms:'/affiliationFields/createForm',
        updateForms:'/affiliationFields/updateForm',
        getFormsDetail:'/affiliationFields/findFormsDetail',
        deleteAffiliationForm: '/affiliationFields/deleteForm',
        updateGeneralParameter: '/parameter/update'
    },
    dashBoardApi: {
        baseUrl: '/api/ms-core-dashboard/core/v1.0',
        port: '443',
        getDashboardData: '/dashboard/find',
        getTendersByTitle: '/dashboard/tender/findByTitle',
        getCompaniesData: '/dashboard/findStoreName',
        getPayrollDasboardData: '/dashboard/dashboardByConciliation',
        getStoreDasboardData: '/dashboard/dashboardByStore',
        getGeneralDashData: '/dashboardGeneral/dashboardByGeneral',
        getEmploymentDashData: '/dashboardGeneral/dashboardByEmployment',
        getTenderDashData: '/dashboardGeneral/dashboardByPurchase',
        getMarketDashData: '/dashboardGeneral/dashboardByMarket',
        getAcademyDashData: '/dashboardGeneral/dashboardByPurchase',
        getDeliveryDashData: '/dashboardGeneral/dashboardByPurchase',
        getNetworksHistorial: '/dashboardGeneral/find',
        saveNetworkHistory: '/dashboardGeneral/create'
    },
    orderApi: {
        baseUrl: '/api/ms-comp-order/order/v1.0',
        port: '443',
        getOrders: '/orderDashboard/find',
        getOrderDetail: '/order/findOrder',
        editOrderStatus: '/orderDashboard/updateOrder',
        getOrderDelivery: '/order/findDelivery',
        getPenalties: '/penaltyController/penalty',
        createPenalty: '/penaltyController/penalty',
        deletePenalty: '/penaltyController/penalty'
    },
    mapsKey: 'AIzaSyBwd8oLAgcR9pDCt5tmZD85LZQtkca4LCE',
    googleConfig: {
        clientId: 'xxxxxxx'
    },
    facebookConfig: {
        appId: 'xxxxxxx'
    }
}
export default environment;
