/**
 * Archivo que contiene la descripción de las acciones redux
 * @author Jesús Alberto Sarmiento Carrillo <mailto:jasarmiento@telconet.ec>
 * @version 1.0
 * @since 16/02/2021
 */


/** ACCIONES DE AUTENTICACIÓN */
export const ADD_AUTHENTICATED_USER = 'ADD_AUTHENTICATED_USER';
export const AUTHENTICATED_USER_LOADING = 'AUTHENTICATED_USER_LOADING';
export const AUTHENTICATED_USER_FAILED = 'AUTHENTICADED_USER_FAILED';

/** ACCIONES DE DATOS DE USUARIOS REGISTRADOS */
export const ADD_REGISTERED_USERS = 'ADD_REGISTERED_USERS';
export const REGISTERED_USERS_LOADING = 'REGISTERED_USERS_LOADING';
export const REGISTERED_USERS_FAILED = 'REGISTERED_USERS_FAILED';
/** ACCIONES DE DATOS DE PUBLICACIONES REGISTRADAS */
export const ADD_REGISTERED_TENDERS = 'ADD_REGISTERED_TENDERS';
export const REGISTERED_TENDERS_LOADING = 'REGISTERED_TENDERS_LOADING';
export const REGISTERED_TENDERS_FAILED = 'REGISTERED_TENDERS_FAILED';
/** ACCIONES DE DATOS DE POSTULACINES */
export const ADD_POSTULATIONS = 'ADD_POSTULATIONS';
export const POSTULATIONS_LOADING = 'POSTULATIONS_LOADING';
export const POSTULATIONS_FAILED = 'POSTULATIONS_FAILED';

/** ACCIONES DE POSTULACIONES POR LICITACION */
export const ADD_POSTULATIONS_BY_TENDER = 'ADD_POSTULATIONS_BY_TENDER';
export const POSTULATIONS_BY_TENDER_LOADING = 'POSTULATIONS_BY_TENDER_LOADING';
export const POSTULATIONS_BY_TENDER_FAILED = 'POSTULATIONS_BY_TENDER_FAILED';

/** ACCIONES DE TAREAS CREADAS */
export const ADD_REGISTERED_TASKS = 'ADD_REGISTERED_TASKS';
export const REGISTERED_TASKS_LOADING = 'REGISTERED_TASKS_LOADING';
export const REGISTERED_TASKS_FAILED = 'REGISTERED_TASKS_FAILED';

/** ACCIONES DE INICIO DE SESIÓN */
export const ADD_ACCESS = 'ADD_ACCESS';
export const ACCESS_LOADING = 'ACCESS_LOADING';
export const ACCESS_FAILED = 'ACCESS_FAILED';

/** ACCIONES DE POSTULAICONES POR ESTADO */
export const ADD_POSTULATION_STATES = 'ADD_POSTULATION_STATES';
export const POSTULATION_STATES_LOADING = 'POSTULATION_STATES_LOADING';
export const POSTULATION_STATES_FAILED = 'POSTULATION_STATES_FAILED';


/** ACCIONES DE POSTULACIONES POR RANGO */
export const ADD_TENDER_POSTULATIONS_BY_RANGE = 'ADD_TENDER_POSTULATIONS_BY_RANGE';
export const TENDER_POSTULATIONS_BY_RANGE_LOADING = 'TENDER_POSTULATIONS_BY_RANGE_LOADING';
export const TENDER_POSTULATIONS_BY_RANGE_FAILED = 'TENDER_POSTULATIONS_BY_RANGE_FAILED';

/** ACCIONES DE PARAMETROS DE INGRESO DE KATS */
export const ADD_PARAMETERS_IN = 'ADD_PARAMETERS_IN';
export const PARAMETERS_IN_LOADING = 'PARAMETERS_IN_LOADING';
export const PARAMETERS_IN_FAILED = 'PARAMETERS_IN_FAILED';

/** ACCIONES DE PARÁMETROS DE EGRESOS DE KATS */
export const ADD_PARAMETERS_OUT = 'ADD_PARAMETERS_OUT';
export const PARAMETERS_OUT_LOADING = 'PARAMETERS_OUT_LOADING';
export const PARAMETERS_OUT_FAILED = 'PARAMETERS_OUT_FAILED';

/** ACCIONES DE LISTA DE ADMINISTRADORES */
export const ADD_ADMIN_LIST = 'ADD_ADMIN_LIST';
export const ADMIN_LIST_LOADING = 'ADMIN_LIST_LOADING';
export const ADMIN_LIST_FAILED = 'ADMIN_LIST_FAILED';

/** ACCIONES DE LISTA DE SOLICITUDES DE AFILIACIÓN */
export const ADD_AFFILIATION_REQUESTS = 'ADD_AFFILIATION_REQUESTS';
export const AFFILIATION_REQUESTS_LOADING = 'AFFILIATION_REQUESTS_LOADING';
export const AFFILIATION_REQUESTS_FAILED = 'AFFILIATION_REQUESTS_FAILED';
export const CHANGE_TOTAL_AFFILIATIONS = 'CHANGE_TOTAL_AFFILIATIONS';

/** ACCIONES DE CAMPOS DE FORMULARIO DINÁMICO */
export const ADD_FIELDS_LIST = 'ADD_FIELDS_LIST';
export const FIELDS_LIST_LOADING = 'FIELDS_LIST_LOADING';
export const FIELDS_LIST_FAILED = 'FIELDS_LIST_FAILED';

/** ACCIONES DE LISTAS DE FORMULARIOS DINÁMICO */
export const ADD_FORMS_LIST = 'ADD_FORMS_LIST';
export const FORMS_LIST_LOADING = 'FORMS_LIST_LOADING';
export const FORMS_LIST_FAILED = 'FORMS_LIST_FAILED';

/** ACCIONES DE LISTAS DE FORMULARIOS DINÁMICO */
export const ADD_FORMS_DETAIL= 'ADD_FORMS_DETAIL';
export const FORMS_DETAIL_LOADING = 'FORMS_DETAIL_LOADING';
export const ADD_DETAIL_FAILED = 'ADD_DETAIL_FAILED';

/** ACCIONES DE LISTA DE SOLICITUDES DE PAGO DE CITAS MEDICAS */
export const ADD_APPOINTMENT_PAYMENT_REQUESTS = 'ADD_APPOINTMENT_PAYMENT_REQUESTS';
export const APPOINTMENT_PAYMENT_REQUESTS_LOADING = 'APPOINTMENT_PAYMENT_REQUESTS_LOADING';
export const APPOINTMENT_PAYMENT_REQUESTS_FAILED = 'APPOINTMENT_PAYMENT_REQUESTS_FAILED';
export const CHANGE_TOTAL_APPOINTMENT_PAYMENT = 'CHANGE_TOTAL_APPOINTMENT_PAYMENT';
/** ACCIONES DE DASHBOARD DESCUENTO A NÓMINA */
export const ADD_DASHBOARD_COMPANIES = 'ADD_DASHBOARD_COMPANIES';
export const DASHBOARD_COMPANIES_LOADING = 'DASHBOARD_COMPANIES_LOADING';
export const DASHBOARD_COMPANIES_FAILED = 'DASHBOARD_COMPANIES_FAILED';

/** ACCIONES DE DASHBOARD DESCUENTO A NÓMINA */
export const ADD_DASHBOARD_PAYROLL = 'ADD_DASHBOARD_PAYROLL';
export const DASHBOARD_PAYROLL_LOADING = 'DASHBOARD_PAYROLL_LOADING';
export const DASHBOARD_PAYROLL_FAILED = 'DASHBOARD_PAYROLL_FAILED';

/** ACCIONES DE DASHBOARD TIENDA EN LÍNEA */
export const ADD_DASHBOARD_STORE = 'ADD_DASHBOARD_STORE';
export const DASHBOARD_STORE_LOADING = 'DASHBOARD_STORE_LOADING';
export const DASHBOARD_STORE_FAILED = 'DASHBOARD_STORE_FAILED';

/** ACCIONES DE HORA DE SERVIDOR */
export const ADD_SERVER_DATE = 'ADD_SERVER_DATE';
export const SERVER_DATE_LOADING = 'SERVER_DATE_LOADING';
export const SERVER_DATE_FAILED = 'SERVER_DATE_FAILED';

/** ACCIONES DE DATA GENERAL DEL DASHBOARD */
export const LOAD_GENERAL_DASH_DATA = 'LOAD_GENERAL_DASH_DATA';
export const GENERAL_DASH_DATA_LOADING = 'GENERAL_DASH_DATA_LOADING';
export const GENERAL_DASH_DATA_FAILED = 'GENERAL_DASH_DATA_FAILED';

/** ACCIONES DE DATA DE DASHBOARD DE EMPLEO */
export const LOAD_EMPLOYMENT_DASH_DATA = 'LOAD_EMPLOYMENT_DASH_DATA';
export const EMPLOYMENT_DASH_DATA_LOADING = 'EMPLOYMENT_DASH_DATA_LOADING';
export const EMPLOYMENT_DASH_DATA_FAILED = 'EMPLOYMENT_DASH_DATA_FAILED';

/** ACCIONES DE DATA DE DASHBOARD DE TENDER */
export const LOAD_TENDER_DASH_DATA = 'LOAD_TENDER_DASH_DATA';
export const TENDER_DASH_DATA_LOADING = 'TENDER_DASH_DATA_LOADING';
export const TENDER_DASH_DATA_FAILED = 'TENDER_DASH_DATA_FAILED';

/** ACCIONES DE DATA DE DASHBOARD DE MERCADO */
export const LOAD_MARKET_DASH_DATA = 'LOAD_MARKET_DASH_DATA';
export const MARKET_DASH_DATA_LOADING = 'MARKET_DASH_DATA_LOADING';
export const MARKET_DASH_DATA_FAILED = 'MARKET_DASH_DATA_FAILED';

/** ACCIONES DE DATA DE DASHBOARD DE ACADEMIA */
export const LOAD_ACADEMY_DASH_DATA = 'LOAD_ACADEMY_DASH_DATA';
export const ACADEMY_DASH_DATA_LOADING = 'ACADEMY_DASH_DATA_LOADING';
export const ACADEMY_DASH_DATA_FAILED = 'ACADEMY_DASH_DATA_FAILED';

/** ACCIONES DE DATA DE DASHBOARD DE DELIVERY */
export const LOAD_DELIVERY_DASH_DATA = 'LOAD_DELIVERY_DASH_DATA';
export const DELIVERY_DASH_DATA_LOADING = 'DELIVERY_DASH_DATA_LOADING';
export const DELIVERY_DASH_DATA_FAILED = 'DELIVERY_DASH_DATA_FAILED';

/** ACCIONES DE HISTORIAL DE PAUTAS EN REDES SOCIALES */
export const LOAD_NETWORKS_HISTORY = 'LOAD_NETWORKS_HISTORY';
export const NETWORKS_HISTORY_LOADING = 'NETWORKS_HISTORY_LOADING';
export const NETWORKS_HISTORY_FAILED = 'NETWORKS_HISTORY_FAILED';

/** ACCIONES PARA PARAMETROS GENERALES */
export const ADD_PARAMETERS = 'ADD_PARAMETERS';
export const PARAMETERS_LOADING = 'PARAMETERS_LOADING';
export const PARAMETERS_FAILED = 'PARAMETERS_FAILED';

/** ACCIONES DE PEDIDOS */
export const LOAD_ORDERS_LIST = 'LOAD_ORDERS_LIST';
export const ORDERS_LIST_LOADING = 'ORDERS_LIST_LOADING';
export const ORDERS_LIST_FAILED = 'ORDERS_LIST_FAILED';

/** ACCIONES DE DETALLE DE ORDEN */
export const ADD_ORDER_DETAIL = 'ADD_ORDER_DETAIL';
export const ORDER_DETAIL_LOADING = 'ORDER_DETAIL_LOADING';
export const ORDER_DETAIL_FAILED = 'ORDER_DETAIL_FAILED';

/** ACCIONES DE DELIVERY DE ORDEN */
export const ADD_ORDER_DELIVERY = 'ADD_ORDER_DELIVERY';
export const ORDER_DELIVERY_LOADING = 'ORDER_DELIVERY_LOADING';
export const ORDER_DELIVERY_FAILED = 'ORDER_DELIVERY_FAILED';

/** ACCIONES DE FILTROS DE ÓRDENES */
export const CHANGE_ORDERS_FILTER = 'CHANGE_ORDERS_FILTER';

/** ACCIONES DE LAZY PARAMS DE ORDERS */
export const CHANGE_ORDERS_LAZY_PARAMS = 'CHANGE_ORDERS_LAZY_PARAMS';

/** ACCIONES DE CATALOGO DE MOTIVOS DE PENALIDAD */
export const ADD_PENALTY_REASONS = 'ADD_PENALTY_REASONS';
export const PENALTY_REASONS_LOADING = 'PENALTY_REASONS_LOADING';
export const PENALTY_REASONS_FAILED = 'PENALTY_REASONS_FAILED';

/** ACCIONES DE OBTENER PENALIDADES */
export const ADD_PENALTIES = 'ADD_PENALTIES';
export const PENALTIES_LOADING = 'PENALTIES_LOADING';
export const PENALTIES_FAILED = 'PENALTIES_FAILED';